import { render, staticRenderFns } from "./Webchat.vue?vue&type=template&id=a88073c6"
import script from "./Webchat.vue?vue&type=script&lang=js"
export * from "./Webchat.vue?vue&type=script&lang=js"
import style0 from "./Webchat.vue?vue&type=style&index=0&id=a88073c6&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports